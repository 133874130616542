import React, { useContext, useEffect } from 'react';

import { Table } from "ant-table-extensions";
import "./QueryResult.css"
import { SideMenuContext } from '../context/sidemenu';
import { ToastContainer } from 'react-toastify';
import { useLocation} from "react-router-dom";
import { Card, Typography } from 'antd';
import { formatDate } from '../util/formatDate';
import  "./PastOrderDetails.css";

function PastOrderDetails(){
    const {setSelectedMenuItem } = useContext(SideMenuContext)
    const loc = useLocation();
    const { quoteId, requestDate, quoteSpecs, additionalRequests } = loc.state || {};
    const { Paragraph } = Typography;
    if (!quoteId || !quoteSpecs) {
        return <h2>No details available</h2>;
      }


    const columns = [
        {
            title: 'InChIKey',
            dataIndex: 'inchiKey',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity'
        },
        {
            title: 'Indicative price',
            dataIndex: 'price'
        }
      ];

     // eslint-disable-next-line
     useEffect(() => {
        setSelectedMenuItem("search"); // eslint-disable-line
    },[]); // eslint-disable-line

    const searchesData = [];
    const notEmpty=(Object.keys(quoteSpecs).length !== 0)
    const resultLength=quoteSpecs.length
    if (notEmpty){
        for (let i = 0; i < resultLength; i++) {
            searchesData.push({
            key:i,
            inchiKey: quoteSpecs[i]["inchiKey"],
            quantity: quoteSpecs[i]["selectedQuantity"],
            price: quoteSpecs[i]["indicativePrice"]
            });
        }
    }

    return(
        <>

            <>
                <ToastContainer position="top-center" />
                {notEmpty ? (
                    <div>
                        <div className='details-container'>
                        <h3>Request number : {quoteId} </h3>
                        <h6> {formatDate(requestDate)} </h6>
                        </div>
                        <Table columns={columns} dataSource={searchesData} showSorterTooltip={{ target: 'sorter-icon' }}/>
                        {
                            additionalRequests && additionalRequests.trim()?
                            (<Card title="Additional requests" className="notes-card">
                                <Paragraph style={{ marginTop: '10px' }}>
                                {additionalRequests}
                                </Paragraph>
                            </Card>
                            ):
                            (<Card className="notes-card" >
                                <Paragraph style={{ marginTop: '10px' }}>
                                No additional requests
                                </Paragraph>
                            </Card>)
                        }

                    </div> ) :
                (
                    <div>
                        <h1>No past orders found!</h1>
                    </div>
                )}
                    </>

        </>
    );
}

export default PastOrderDetails;
