import React, { useState, useContext, useEffect } from 'react';
import { API, Auth } from "aws-amplify";

import { Table } from "ant-table-extensions";
import "./QueryResult.css"
import { SideMenuContext } from '../context/sidemenu';
import { ToastContainer , toast} from 'react-toastify';
import { Link} from "react-router-dom";
import { cleanAndParseJson } from '../util/cleanJson';
import { formatDate } from '../util/formatDate';

function OrderHistory(){
    const {setSelectedMenuItem } = useContext(SideMenuContext)
    const [dataQuote, setDataQuote] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const columns = [
        {
            title: 'Request no',
            dataIndex: 'quoteId',
        },
        {
            title: 'Request date',
            dataIndex: 'requestDate',
            defaultSortOrder: 'descend',
            sorter: (a, b) => new Date(b.requestDate) - new Date(a.requestDate)
        },
        {
            title: 'Request Addressed To',
            dataIndex: 'recepient'
        },
        {
            title: 'Request details',
            dataIndex: 'requestDetails'
        }
      ];

    async function handlePastOrders () {
        setIsLoading(true);
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const request = {
            headers: {
                Authorization: "Bearer " + token
            }
        };
        const jsonDataQuote= await API.get("diadem", "/pastOrders", request);
        if (jsonDataQuote.statusCode===200){
            setIsLoading(false);
            setDataQuote(jsonDataQuote["body"]);
          }
        else{
            const body=JSON.parse(jsonDataQuote.body)
            if (body && body.exception){
                toast.error(body.exception);
                setIsLoading(false);
            }
            }
        }

     // eslint-disable-next-line
     useEffect(() => {
        setSelectedMenuItem("search"); // eslint-disable-line
        handlePastOrders(); // eslint-disable-line
    }, []); // eslint-disable-line

    const searchesData = [];
    const notEmpty=(Object.keys(dataQuote).length !== 0)
    const resultLength=dataQuote.length
    if (notEmpty){
        for (let i = 0; i < resultLength; i++) {
            const quoteSpecsJson= cleanAndParseJson(dataQuote[i]["quoteSpecs"])
            searchesData.push({
            key:i,
            quoteId: dataQuote[i]["quoteId"],
            requestDate: formatDate(dataQuote[i]["createdAt"]),
            recepient: dataQuote[i]["firstName"]+' '+dataQuote[i]["lastName"],
            requestDetails: (
                <Link
                  to="/pastOrderDetails"
                  state={{
                    quoteId: dataQuote[i]["quoteId"],
                    requestDate: dataQuote[i]["createdAt"],
                    quoteSpecs: quoteSpecsJson,
                    additionalRequests: dataQuote[i]["additionalRequests"]
                  }}
                >
                  Molecule Details
                </Link>
              )
            });
        }
    }

    return(
        <>
            {isLoading ? (
                <div class="loading" id="loading-wrapper">
                <div id="loading-text">LOADING</div>
                <div id="loading-content"></div>
                </div>
            ) : (
                <>
                  <ToastContainer position="top-center" />
                    {notEmpty ? (
                        <div>
                            <h1>Past orders</h1>
                            <Table columns={columns} dataSource={searchesData} showSorterTooltip={{ target: 'sorter-icon' }}/>

                        </div> ) :
                    (
                        <div>
                            <h1>No past orders found!</h1>
                        </div>
                    )}
                        </>
                    )}
        </>
    );
}

export default OrderHistory;
