import React, { useState, useContext, useEffect } from 'react';
import "./QueryResult.css"
import { SideMenuContext } from '../context/sidemenu';
import { API, Auth } from "aws-amplify";
import { ToastContainer , toast} from 'react-toastify';
import { Table } from "ant-table-extensions";
import { Link} from "react-router-dom";
import { formatDate } from '../util/formatDate';

function ComputationAll(){
    const {setSelectedMenuItem } = useContext(SideMenuContext)
    const [isLoading, setIsLoading] = useState({});
    const [dataResult, setDataResult] = useState({});
    const columns = [
        {
            title: 'Serial no',
            dataIndex: 'index',
        },
        {
            title: 'Batch id',
            dataIndex: 'batchId',
        },
        {
            title: 'InChIKey',
            dataIndex: 'molId',
            render: (molId) => <Link to={`/moleculeView/${molId}`} >{molId}</Link>
        },

        {
            title: 'Calculator',
            dataIndex: 'calculator',
        },
        {
            title: 'Property',
            dataIndex: 'property',
        },
        {
            title: 'Run Status',
            dataIndex: 'runStatus',
        },
        {
            title: 'Submitted At',
            dataIndex: 'createdAt',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        },
      ];
    async function handlePastResults () {
        setIsLoading(true);
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const request = {
            headers: {
                Authorization: "Bearer " + token
            }
        };
        const jsonDataResult= await API.get("diadem", "/calculation", request);
        if (jsonDataResult.statusCode===200){
            setIsLoading(false);
            setDataResult(jsonDataResult["body"]["allResultList"]);
          }
        else{
            const body=JSON.parse(jsonDataResult.body)
            if (body && body.exception){
                toast.error(body.exception);
                setIsLoading(false);
            }
            }
    }
    // eslint-disable-next-line
    useEffect(() => {
        setSelectedMenuItem("dashboard"); // eslint-disable-line
        handlePastResults(); // eslint-disable-line
    }, []); // eslint-disable-line

    const searchesData = [];
    const notEmpty=(Object.keys(dataResult).length !== 0)
    const resultLength=dataResult.length
    if (notEmpty){
        for (let i = 0; i < resultLength; i++) {
            searchesData.push({
            key:i,
            index: i+1,
            batchId: dataResult[i]["batchId"],
            molId: dataResult[i]["molId"],
            runStatus: dataResult[i]["runStatus"],
            calculator: dataResult[i]["calculatorId"],
            property: dataResult[i]["propertyTypeName"],
            createdAt: formatDate(dataResult[i]["createdAt"]),
            });
        }
    }

    return (
        <>
            {isLoading ? (
                <div class="loading" id="loading-wrapper">
                <div id="loading-text">LOADING</div>
                <div id="loading-content"></div>
                </div>
            ) : (
                <>
                  <ToastContainer position="top-center" />
                    {notEmpty ? (
                        <div>
                            <h1>Past computations</h1>
                            <Table columns={columns} dataSource={searchesData} showSorterTooltip={{ target: 'sorter-icon' }}/>

                        </div> ) :
                    (
                        <div>
                            <h1>No past searches found!</h1>
                        </div>
                    )}
                        </>
                    )}
        </>

    )
}

export default ComputationAll;
